import "./style.scss"

(function($){
    $(document).ready(function(){
        const form = $(".wp-block-gef-form-container > form");
        
        if(form) {

            $(form).on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) { 
                e.preventDefault();
                return false;
            }
            });

            const allSteps  = $(form).find(".wp-block-gef-form-step");
            const TopNav    = $(".wp-block-gef-form-container .form-top-nav");
            const bottomNav = $(".wp-block-gef-form-container .form-bottom-nav");

            let activeStep = 0;

            createMenu();
            setMenuItemActive();
            createButtons();
            
            function createMenu(){
                for (const [key, value] of Object.entries(allSteps)) {
                    if (key != 'length' && key != 'prevObject'){
                        const id    =   value.getAttribute('id');
                        const name  =   value.dataset.titel;

                        const item  =   document.createElement('div');
                        item.id = id;
                        item.classList.add('tab');
                        item.innerText = name;

                        TopNav[0].appendChild(item);
                    }
                }
            }

            function setMenuItemActive(){
                var items = $(".form-top-nav > div");
                var activeItems = $(".form-top-nav > div.active");

                if (activeItems != null && Object.keys(activeItems).length > 0){
                    for (let item of activeItems){
                        item.classList.remove("active");
                    }
                }
                
                $(items[activeStep]).addClass("active");
                let activeTabList = document.getElementsByClassName('activeTab');
                
                if (activeTabList != null && Object.keys(activeTabList).length > 0){
                    for (let item of activeTabList){
                        item.classList.remove("activeTab");
                    }
                }
                
                allSteps[activeStep].classList.add('activeTab'); 
            }

            function createButtons(){
                var backButton = null;     

                if(activeStep > 0){
                    var backButton = document.createElement('div');
                    backButton.innerText = 'Vorige stap';
                    backButton.onclick = lastStep;
                }

                if (activeStep < ( allSteps.length - 1)){               
                    var button = document.createElement('div');
                    button.innerText = 'Volgende stap';
                    button.onclick = nextStep;

                }
                else{
                    var button = document.createElement('div');
                    button.innerText = 'Afronden';
                    button.onclick = confirmForm;
                }
                while (bottomNav[0].firstChild) {
                    bottomNav[0].removeChild(bottomNav[0].firstChild);
                }
                if (backButton != null ){
                    bottomNav[0].appendChild(backButton);
                }
                bottomNav[0].appendChild(button);
            }
            
            function nextStep(){
                if ( validateForm() ) {
                    activeStep ++;
                    setMenuItemActive();
                    createButtons();
                }
            }

            function lastStep(){
                activeStep --;
                setMenuItemActive();
                createButtons();
            }

            function confirmForm(){
                if ( validateForm() ) {
                    let url = $(form).attr('action');
                    let sender = $(form).find("input[data-sender='true']").attr('name');
                    console.log(sender);
                    
                    let data = {
                        'form_id': $(form).data('post-id'),
                        'form_data': $(form).serialize(),
                        'form_sender': sender
                    }

                    $.post(url, data, (response) => {
                        if (response.success){
                            $('.wp-block-gef-form-container').slideUp( 750, () => {
                              
                                $('.wp-block-gef-form-container').parent().html('<p>Bedankt voor het invullen van dit formulier!</p>');
                                    
                            });
                        }
                    });
                }
            }

            // returns true or false depending on if the form step is valid
            function validateForm(){
                // collects all the inputs of the active step
                let inputs = allSteps[activeStep].querySelectorAll('.wp-block-gef-form-rating, .wp-block-gef-form-input');
                
                // sets our holder variable to true
                let validated = true;

                for (let input of inputs){
                    switch ( true ) {
                        case input.classList.contains('wp-block-gef-form-rating'):
                            if( input.querySelectorAll('input[type=radio]:checked').length == 0 ){
                                validated = false;
                                // TODO: add error message
                                console.log('rating not checked');
                                input.querySelector('div.rateContainer > div.rate').classList.add('error');
                                setTimeout(() => {
                                    input.querySelector('div.rateContainer > div.rate').classList.remove('error');
                                }, 2500);
                            }
                            else{
                                input.querySelector('div.rateContainer > div.rate').classList.remove('error');
                            }
                            break;
                        case input.classList.contains('wp-block-gef-form-input'):
                            if(input.querySelector('input').value.length < 1) {
                                // TODO: add error message
                                console.log('input is empty');
                                validated = false;
                                
                                // gives the input element error class
                                input.querySelector('input').classList.add('error');
                            } else {
                                // removes possible error class
                                input.querySelector('input').classList.remove('error');
                            }
                            break;
                        default:
                            break;
                    }
                }

                return validated ? true : false; 
            }
        }
    });
}(jQuery));
